export class ProductWebLinks {
	constructor() { }

	setup() {
		const links = document.querySelectorAll('.gbrn-product-weblink');

		links.forEach(link => link.addEventListener('click', evt => {
			//console.log('product weblink click', evt.target.dataset.product);

			const link = 'https://products.gabraun.com/product/' + evt.target.dataset.product;

			copyTextToClipboard(link)
				.then(function () {
					console.log('Async: Copying to clipboard was successful!');

					const ttip = evt.target.querySelector('.ttip');

					ttip.classList.add('visible');

					setTimeout(function () {
						ttip.classList.remove('visible');
					}, 3000);

				}, function (err) {
					console.error('Async: Could not copy text: ', err);
				});
		}));
	}
}

/**
 * https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
 */
function fallbackCopyTextToClipboard(text) {
	var textArea = document.createElement("textarea");
	textArea.value = text;

	// Avoid scrolling to bottom
	textArea.style.top = "0";
	textArea.style.left = "0";
	textArea.style.position = "fixed";

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	var successful = false;

	try {
		successful = document.execCommand('copy');
		var msg = successful ? 'successful' : 'unsuccessful';
		console.log('Fallback: Copying text command was ' + msg);
	} catch (err) {
		console.error('Fallback: Oops, unable to copy', err);
	}

	document.body.removeChild(textArea);

	return successful;
}

function copyTextToClipboard(text) {
	if (!navigator.clipboard) {
		var fallbackSuccess = fallbackCopyTextToClipboard(text);
		if (fallbackSuccess) return Promise.resolve();
		return Promise.reject();
	}
	return navigator.clipboard.writeText(text);
}