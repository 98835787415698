import Swiper from 'swiper';

import { Navigation, Pagination, EffectFade } from 'swiper/modules';
import { AnchorMenu } from './anchor-menus';

/**
	* FeaturesGalleries
	* 
	* class to manage Special Features and Competitive Info block components
	*
	* TODOS:
	*   
	*/
export class FeaturesGalleries {
	constructor() {
		this.init = false;
		this.blocks = [];
	}

	setup() {

		if (this.init) return;

		this.init = true;

		const els = document.querySelectorAll('.features-gallery');

		let i, block;

		for (i = 0; i < els.length; i++) {
			block = new FeaturesGallery(els[i]);
			this.blocks.push(block);
		}
	}
}

/**
	* FeaturesGallery
	* 
	* class to handle single Special Features or Competitive Info block
	*
	* @param el NodeElement the dom block container 
	*/
class FeaturesGallery {
	constructor(el) {
		console.log('FeaturesGallery', el);

		this.el = el;
		this.ddMenu = null;
		this.swiper = null;

		this.dom = {
			ssEl: el.querySelector('.swiper'),
			ddMenu: el.querySelector('.trs-dd-menu'),
		};

		// init swiper
		if (this.dom.ssEl) {
			if (this.el.querySelectorAll('.swiper-slide').length <= 1) {

				this.el.classList.add('swiper-not-initialized');

			} else {

				this.swiper = new Swiper(this.dom.ssEl, {
					loop: false,
					slidesPerView: 1,
					autoHeight: true,
					effect: 'fade',
					fadeEffect: {
						crossFade: true,
					},
					modules: [Navigation, Pagination, EffectFade],
					navigation: {
						prevEl: this.el.querySelector('.swiper-button-prev'),
						nextEl: this.el.querySelector('.swiper-button-next'),
					},
					pagination: {
						type: 'fraction',
						el: this.el.querySelector('.swiper-pagination'),
					},
					on: {
						init: this.onSwiperInit.bind(this),
						slideChange: this.onSlideChange.bind(this),
					}
				});
			}
		}

		// init AnchorMenu
		if (this.dom.ddMenu) {

			this.ddMenu = new AnchorMenu(this.dom.ddMenu, {
				class: 'trs-dd-menu',
				clickHandler: this.anchorClick.bind(this),
			});
		}

		// init print button
		const print = this.el.querySelector('.features-gallery__print-button');
		if (print) {
			print.addEventListener('click', this.printOffscreen.bind(this));
		}
	}

	/**
	 * place special features in offscreen div to print
	 */
	printOffscreen() {

		// create wrapper div and append slide contents (get rid of slide div)
		const wrapper = document.createElement('div');
		const slides = this.el.querySelectorAll('.swiper-slide');

		if (!slides) return;

		wrapper.setAttribute('id', 'export-features-wrapper');

		let div;
		slides.forEach(slide => {
			div = document.createElement('div');
			div.innerHTML = slide.innerHTML;
			wrapper.append(div);
		});


		// add a class to body so default browser print is unaffected
		document.body.classList.add('exporting-features');

		// append our export slides to the body
		document.body.append(wrapper);

		// add listener for close of print dialog to remove the export slides and body class
		window.onafterprint = () => {
			wrapper.remove();
			document.body.classList.remove('exporting-features');
		}

		// collect all slide images to check/listen to their loading
		const images = Array.from(wrapper.querySelectorAll('img')).filter(img => !img.complete);

		// maybe unneccessary but set loading to eager to avoid offscreen issues
		images.forEach(img => img.setAttribute('loading', 'eager'));

		// wait for loading, then trigger print dialog
		// https://stackoverflow.com/questions/11071314/javascript-execute-after-all-images-have-loaded
		Promise.all(images.map(img => new Promise(resolve => { img.onload = img.onerror = resolve; })))
			.then(() => window.print());
	}

	// AnchorMenu click handler override
	anchorClick(evt) {
		console.log('trs dd menu anchor click', evt.target, evt.target.hash);

		evt.preventDefault();

		if (!this.swiper) return;

		const slideIndex = (evt.target.hasAttribute('data-slide-index')) ? parseInt(evt.target.dataset.slideIndex, 10) : 0;

		this.swiper.slideTo(slideIndex);
	}

	// add class to block when swiper is gtg
	// load the non-first slides' assets
	onSwiperInit() {

		//this.loadSlideAssets();

		this.el.classList.add('swiper-initialized');
	}

	// update AnchorMenu title on slide change
	onSlideChange() {
		const idx = this.swiper.realIndex;
		const activeLI = this.dom.ddMenu.querySelectorAll('li')[idx];
		this.ddMenu.updateTitle(activeLI.querySelector('a').innerHTML);
	}

	// load slide assets
	loadSlideAssets() {

		const elsWithDataSrc = this.el.querySelectorAll('.swiper-slide:not(first-child) [data-src], .swiper-slide:not(first-child) [data-srcset]');

		let i, elmnt;

		for (i = 0; i < elsWithDataSrc.length; i++) {
			elmnt = elsWithDataSrc[i];

			//console.log(elmnt);

			if (elmnt.dataset.hasOwnProperty('srcset')) {
				elmnt.srcset = elmnt.dataset.srcset;
			}

			if (elmnt.dataset.hasOwnProperty('src')) {
				elmnt.src = elmnt.dataset.src;
			}
		}
	}
}