import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText);

/**
 * ScrollManager
 * class to handle scrolling animations
 * and all click events triggering them!
 * maintains ScrollSmoother instance "smoother"
 */
export class ScrollManager {
	constructor() {
		this.smoother = null;
		this.lastScrollTransform = null;

		// monkey-patch to history pushState
		// https://stackoverflow.com/questions/4570093/how-to-get-notified-about-changes-of-the-history-via-history-pushstate
		(function (history) {
			var pushState = history.pushState;
			history.pushState = function (state) {
				if (typeof history.onpushstate == "function") {
					history.onpushstate({ state: state });
				}
				return pushState.apply(history, arguments);
			}
		})(window.history);
	}

	setup() {

		// create the smooth scroller
		this.smoother = ScrollSmoother.create({
			wrapper: "",
			content: "#app",
			smooth: 1, //2,
			//smoothTouch: 0.1,
			normalizeScroll: true, // prevents address bar from showing/hiding on most devices, solves various other browser inconsistencies
			ignoreMobileResize: true, // skips ScrollTrigger.refresh() on mobile resizes from address bar showing/hiding
			effects: true,
			preventDefault: true
		});

		// scrollTo on hash changes to history
		window.onpopstate = history.onpushstate = (e) => {
			console.log('onpushstate', e.state);
			if (e.state && e.state.hash) {
				this.scrollTo(e.state.hash);
			}
		};

		// add a handler to the menu drawer open, close
		this.handleHashAnchors();

		// add a handler to the menu drawer open, close
		this.handleMenuDrawerToggle();

		// sticky main nav elements
		this.initStickyMainNav();

		// add fade in on viewport with class "fade-in"
		this.initFadeInAnime();

		this.initBlockAnimes();

		// window.onhashchange = this.onHashChange.bind(this);

		this.checkInitialHash();

		// may want to add these for better default browser printing
		// window.onbeforeprint = () => {
		// 	this.smoother.paused(true);
		// }
		// window.onafterprint = () => {
		// 	this.smoother.paused(false);
		// }
	}

	checkInitialHash() {
		let hash = window.location.hash;
		console.log('ScrollManager::checkInitialHash', hash);
		if (!hash) return;
		hash = hash.slice(1).split('/')[0];
		this.scrollTo(hash);
	}

	scrollTo(id, yOffset) {
		console.log('scrollTo called with id of ' + id);

		if (!this.smoother) return;

		const el = document.querySelector(id);

		console.log('scroll el', el);

		if (!el) return;

		const offset = (yOffset) ? yOffset : jQuery('.anchor-menu').first().outerHeight();

		const scrollTop = this.smoother.offset(el, 'top top') - offset;

		gsap.to(this.smoother, {
			scrollTop,
			duration: .2,
			onComplete: () => {
				console.log('ScrollManager::scrollTo complete');
			}
		});
	}

	// update location hash using pushState (avoids hashchange event and browser default scroll)
	updateHash(newhash) {
		console.log('ScrollManager::updateHash', newhash);
		history.pushState({ hash: newhash }, '', newhash);
	}

	// manually close menu drawer
	closeMenu() {
		console.log('ScrollManager::closeMenu');

		const drawerToggle = document.querySelector('#drawer-toggle');

		if (drawerToggle.ariaExpanded === 'true') {
			drawerToggle.click();
		}
	}

	// update all hash links to use history pushState instead (and trigger scroller)
	handleHashAnchors() {

		let anchorPoint;

		document.querySelectorAll("a").forEach(anchor => {

			if (!anchor.hash) return;

			// if hash anchor has matching dom el, override its click
			anchorPoint = document.querySelector(anchor.hash);

			if (!anchorPoint) return

			console.log('anchorPoint', anchorPoint);

			anchor.addEventListener("click", (e) => {
				e.preventDefault(anchorPoint);
				this.closeMenu();
				this.updateHash(anchor.hash);
			}, false);

			// (function (anchorPoint) {
			// 	if (anchorPoint) {
			// 		console.log('anchorPoint', anchorPoint);
			// 		anchor.addEventListener("click", function (e) {
			// 			e.preventDefault(anchorPoint);
			// 			this.closeMenu();
			// 			this.updateHash(anchor.hash);
			// 		}, false);
			// 	}
			// })(document.querySelector(anchor.hash));
		});
	}

	// setting body to overflow:hidden when our menu drawer is open
	// prevents drawer toggle from being scrolled away and scrolling of page behind menu :)
	// UPDATE: SmoothScroller negates above, so we pause its scrolling instead
	//         we also remove the transform it adds to the app to prevent menu drawer from taking on app's height
	handleMenuDrawerToggle() {

		const drawerToggle = document.querySelector('#drawer-toggle');

		if (!drawerToggle) return;

		drawerToggle.addEventListener('click', () => {

			// toggle the aria-expaned value
			drawerToggle.ariaExpanded = drawerToggle.ariaExpanded !== 'true';
			document.body.classList.toggle('menu-open');

			const drawerOpen = (drawerToggle.ariaExpanded === 'true') ? true : false;

			console.log('drawerOpen ' + drawerOpen);

			if (this.smoother) {
				this.smoother.paused(drawerOpen);
			} else {
				document.body.style.overflow = (drawerOpen) ? 'hidden' : 'scroll';
			}
		});
	}

	initStickyMainNav() {

		// add header as pinned element
		ScrollTrigger.create({
			trigger: "#header__inner",
			start: "bottom top",
			endTrigger: "#footer",
			end: 99999,
			pin: '#header',
			pinSpacing: false,
			toggleClass: { className: 'pinned', targets: '#header' }
		});
	}

	// https://webdevpuneet.com/fade-in-animation-using-scrolltrigger-gsap/#gsc.tab=0
	initFadeInAnime() {

		const fadeEls = document.querySelectorAll(".fade-in");

		fadeEls.forEach((el) => {

			gsap.set(el, { opacity: 0, y: 20, });

			gsap.to(el, {
				opacity: 1,
				y: 0,
				duration: 1,
				scrollTrigger: {
					trigger: el,
					start: "top bottom",
					end: "bottom top",
					toggleActions: "play none none reset",
					markers: false,
				},
			});
		});
	}

	initBlockAnimes() {

		// featured literature blocks
		const litBlockEls = document.querySelectorAll('.featured-literature');

		gsap.set('.featured-literature .literature-teaser-alt', { opacity: 0, y: 40, scale: .9 });

		litBlockEls.forEach((el) => {

			gsap.to(el.querySelectorAll('.literature-teaser-alt'), {
				opacity: 1,
				y: 0,
				duration: .6,
				scale: 1,
				//delay: (i) => .3 + i * .3,
				stagger: 0.15,
				scrollTrigger: {
					trigger: el,
					start: "top bottom",
					end: "bottom top",
					toggleActions: "play none none reset",
					markers: false,
				},
			});
		});
	}
}