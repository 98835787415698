import { ScrollTrigger } from "gsap/ScrollTrigger";

const ANCHOR_DEFAULTS = {
	class: 'anchor-menu',
	clickHandler: null,
	scroller: null,
};

/**
 * sections available to populate jump menu
 * expected format { section id: link text }
 */
const SECTION_WHITELIST = {
	'product-overview': 'Overview',
	'special-features': 'Special Features',
	'product-literature': 'Literature',
	'product-videos': 'Videos',
	'competitive-information': 'Competitive Information',
	'tm-sheets': 'TM Sheets',
	'contact': 'Contact',
};

/**
 * Manage sticky, anchor based internal menus
 *
 * NOTE:
 *     there are currently two kinds of anchor menu:
 *         AnchorMenu ( select input like with dropdown menu)
 *         JumpMenu ( row of text links )
 *
 *     currently the Menu classes depend on jQuery :(
 */
export class AnchorMenus {
	constructor() {

	}

	setup(scroller) {
		//console.log('AnchorMenuMager::setup');

		let i;

		const anchorMenus = document.querySelectorAll('.anchor-menu, .jump-menu');

		if (anchorMenus.length) {

			console.log(`${anchorMenus.length} anchor menu(s) found`, anchorMenus);

			for (i = 0; i < anchorMenus.length; i++) {

				if (anchorMenus[i].classList.contains('jump-menu')) {
					new JumpMenu(anchorMenus[i]);
				} else {
					new AnchorMenu(anchorMenus[i], { scroller });
				}

				// with SmoothScroller enabled, our sticky elements wont work
				// replicate with ScrollTrigger
				// https://gsap.com/community/forums/topic/33591-position-sticky-with-scrolltrigger/
				if (scroller) {
					ScrollTrigger.create({
						trigger: anchorMenus[i],
						pin: true,
						start: 'top 78px',
						endTrigger: 'html',
						markers: false,
						scrub: false,
						pinSpacing: false,
					});
				}
			}
		}
	}
}

/**
 *  AnchorMenu
 */
export class AnchorMenu {
	constructor(el, options) {


		this.el = el;
		this.options = Object.assign({}, ANCHOR_DEFAULTS, options);
		this.menuOpen = false;

		console.log('AnchorMenu::init', this.options);

		this.dom = {
			heading: this.el.querySelector(`.${this.options.class}__heading`),
			title: this.el.querySelector(`.${this.options.class}__title`),
			menu: this.el.querySelector(`.${this.options.class}__menu`),
		}

		if (this.el.classList.contains('section-menu')) {
			this.createSectionLinks();
		} else {

			const anchors = this.dom.menu.querySelectorAll('a');

			const clickHandler = (!this.options.clickHandler) ? this.anchorClick.bind(this) : this.options.clickHandler;

			for (let i = 0; i < anchors.length; i++) {
				anchors[i].addEventListener('click', clickHandler);
			}
		}

		// add click to window for closing on clicking off heading
		window.addEventListener('click', () => this.closeMenu());

		// add click to heading to toggle menu
		this.dom.heading.addEventListener('click', evt => {

			// prevent above listener closing menu
			evt.stopPropagation();

			if (this.menuOpen) {
				this.closeMenu();
			} else {
				this.openMenu();
			}
		});
	}

	createSectionLinks() {
		console.log('AnchorMenu::createSectionLinks', this.dom);

		// get sibling elements of jump menu
		const siblings = [...this.el.parentNode.querySelectorAll(':scope > *[id]')];

		// filter siblings list by SECTION_WHITELIST
		const sections = siblings.filter((section) => Object.keys(SECTION_WHITELIST).indexOf(section.id) > -1);

		console.log(sections);

		// bail if we don't find any links
		if (!sections.length) return;

		// insert links for found sections
		this.dom.list = this.el.querySelector('.anchor-menu__menu > ul');

		// bail if dom isnt right
		if (!this.dom.list) return;

		let i, section, li, a, txt;

		for (i = 0; i < sections.length; i++) {

			section = sections[i];

			li = document.createElement('li');
			a = document.createElement('a');
			txt = document.createTextNode(SECTION_WHITELIST[section.id]);

			a.href = '#' + section.id;
			a.addEventListener('click', this.anchorClick.bind(this));

			a.appendChild(txt);
			li.appendChild(a);

			this.dom.list.appendChild(li);
		}

		// remove hidden class to show menu
		this.el.classList.remove('hidden');
	}

	// scroll manager adds handler to hash links
	// has them call pushState, which triggers scrollTo
	// so just update menu here
	anchorClick(evt) {
		console.log('AnchorMenu::anchorClick', evt.target, evt.target.hash);
		this.updateTitle(evt.target.innerHTML);
		return;
	}

	// anchorClick(evt) {
	// 	evt.preventDefault();

	// 	const href = evt.target.hash;
	// 	const target = document.querySelector(href)
	// 	let scrollTop;

	// 	if (!target) return;

	// 	console.debug('X', jQuery('.anchor-menu').first().height());

	// 	scrollTop = jQuery(target).offset().top - jQuery('.anchor-menu').first().outerHeight();

	// 	//console.log(`href: ${href}, target: ${target}, scrollTop: ${scrollTop}`);

	// 	jQuery("html, body").stop().animate({
	// 		scrollTop,
	// 	}, {
	// 		duration: 200,
	// 		complete: () => {
	// 			console.log('jquery scroll complete');
	// 			this.updateTitle(evt.target.innerHTML);
	// 			//this.dom.title.innerHTML = evt.target.innerHTML;
	// 		}
	// 	});
	// }

	openMenu() {

		if (this.menuOpen) return;

		jQuery(this.dom.menu).slideDown({
			duration: 200,
			complete: () => {
				this.dom.heading.setAttribute('aria-expanded', true);
				this.el.classList.add('is-open');
				this.menuOpen = true;
			}
		});
	}

	closeMenu() {

		if (!this.menuOpen) return;

		jQuery(this.dom.menu).slideUp({
			duration: 200,
			complete: () => {
				this.dom.heading.setAttribute('aria-expanded', false);
				this.el.classList.remove('is-open');
				this.menuOpen = false;
			}
		});
	}

	updateTitle(title) {
		this.dom.title.innerHTML = title;
	}
}

/**
 * JumpMenu
 */
class JumpMenu {

	constructor(el) {
		console.log('JumpMenu::init');

		this.el = el;

		// get sibling elements of jump menu
		const siblings = [...this.el.parentNode.querySelectorAll(':scope > *[id]')];

		// filter siblings list by SECTION_WHITELIST
		const sections = siblings.filter((section) => Object.keys(SECTION_WHITELIST).indexOf(section.id) > -1);

		//console.log(sections);

		// bail if we don't find any links
		if (!sections.length) return;

		// insert links for found sections
		this.dom = {
			//menu: this.el.querySelector('.jump-menu__menu'),
			list: this.el.querySelector('.jump-menu__menu > ul'),
		}

		// bail if dom isnt right
		if (!this.dom.list) return;

		let i, section, li, a, txt;

		for (i = 0; i < sections.length; i++) {

			section = sections[i];

			li = document.createElement('li');
			a = document.createElement('a');
			txt = document.createTextNode(SECTION_WHITELIST[section.id]);

			a.href = '#' + section.id;
			a.addEventListener('click', this.anchorClick.bind(this));

			a.appendChild(txt);
			li.appendChild(a);

			this.dom.list.appendChild(li);
		}

		// remove hidden class to show menu
		this.el.classList.remove('hidden');
	}

	// bc jump menu links are created after scrollmanager sets its hash anchor click evt
	// these links wont trigger pushState or scrollTo via scrollmanager
	anchorClick(evt) {
		console.log('Jump::anchorClick', evt.target, evt.target.hash);

		evt.preventDefault();

		const href = evt.target.hash;
		const target = document.querySelector(href)
		let scrollTop;

		if (!target) return;

		scrollTop = jQuery(target).offset().top - jQuery('.jump-menu').first().outerHeight();

		//console.log(`href: ${href}, target: ${target}, scrollTop: ${scrollTop}`);

		jQuery("html, body").stop().animate({
			scrollTop,
		}, {
			duration: 200,
			//complete: () => { console.log('jquery scroll complete'); }
		});
	}
}