export const staticFormHelper = function () {
	const forms = document.querySelectorAll('.wpcf7 form, .wpcf7-form');

	//console.log('staticFormHelper', forms);

	forms.forEach((form) => {
		form.addEventListener("submit", function (el) {

			const submit = form.querySelector('input[type="submit"]');

			//console.log('static form submit', submit);

			submit.setAttribute('disabled', true);
			submit.classList.add('d-none');

			const note = document.createElement('div');

			note.classList.add('gab-form-submitting-note');
			note.innerHTML = '<span>submitting...</span>';

			form.appendChild(note);
		});
	});
}