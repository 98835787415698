/**
 * DragAndDrop
 * class to handle draggable files
 */
export class DragAndDrop {
	constructor() {
	}

	setup() {
		const draggableItems = document.querySelectorAll('.draggable');

		for (let i = 0; i < draggableItems.length; i++) {
			let item = draggableItems[i];
			item.ondragstart = (event) => {
				event.preventDefault();
				if(window.electronAPI) window.electronAPI.startDrag( event.target.closest('a').href );
			}
		}
	}
}