//import { HomeHeroProduct } from './modules/home-hero-product';
import { BlockSlideshows } from './modules/block-slideshows';
//import { ProductOverviewGalleries } from './modules/product-overview-galleries';
import { FeaturesGalleries } from './modules/features-galleries';
import { AnchorMenus } from './modules/anchor-menus';
import { ProductVideos } from './modules/product-videos';
import { ScrollManager } from './modules/scroll-manager';
import { ProductWebLinks } from './modules/product-web-links';
import { checkAdRedirect } from './modules/js-redirects';
import { staticFormHelper } from './modules/static-form-helper';
import { DragAndDrop } from './modules/drag-and-drop';

console.log('__GAB_APP_MODE__', window.__GAB_APP_MODE__);

let pwl = null;

if (!window.__GAB_APP_MODE__) {
	checkAdRedirect();
} else {
	pwl = new ProductWebLinks();
}

//const homeHeroProduct = new HomeHeroProduct();
const blockSlideshows = new BlockSlideshows();
//const productOverviewGalleries = new ProductOverviewGalleries();
const anchorMenus = new AnchorMenus();
const featuresGalleries = new FeaturesGalleries();
const pv = new ProductVideos();
const scrollManager = new ScrollManager();
const dragAndDrop = new DragAndDrop();

window.addEventListener('load', function () {

	// smooth scroller and hash anchor handler
	scrollManager.setup();

	// anchor menus and jump menus (also used by featuresGalleries)
	anchorMenus.setup(scrollManager);

	// setup home hero product (3D)
	//homeHeroProduct.setup();

	// blocks with generic slideshows
	blockSlideshows.setup();

	// product image galleries
	// productOverviewGalleries.setup();

	// special features & competitive information
	featuresGalleries.setup();

	// product videos
	pv.setup();

	// product web links
	if (pwl) {
		pwl.setup();
	}

	dragAndDrop.setup();

	staticFormHelper();
});