//import Swiper from 'swiper';
//import { Navigation, Pagination } from 'swiper/modules';

export class ProductVideos {
	constructor() { }

	// below handles playing videos using our gab-video classes, layout
	// plays video when overlay is clicked and shows controls
	setup() {

		const videoButtons = document.querySelectorAll('.gab-video__video .overlay');
		let i, videoButton, videoEl;

		for (i = 0; i < videoButtons.length; i++) {

			videoButton = videoButtons[i];

			videoEl = videoButton.parentNode.querySelector('video');

			(function (button, video) {

				button.addEventListener('click', function () {
					video.play();
					button.classList.add('hidden');
					video.setAttribute('controls', true);
				}, false);

				video.addEventListener('ended', function () {
					video.removeAttribute('controls');
					button.classList.remove('hidden');
				});

			})(videoButton, videoEl);
		}

		// alternate method of hooking wp-block-videos styled to match our gab-videos
		// const wpBlockVideos = document.querySelectorAll('.wp-block-video');
		// for (i = 0; i < wpBlockVideos.length; i++) {
		// 	videoButton = wpBlockVideos[i];
		// 	videoEl = videoButton.querySelector('video');
		// 	(function (button, video) {
		// 		video.removeAttribute('controls');
		// 		button.addEventListener('click', function () {
		// 			video.play();
		// 			button.classList.add('is-playing');
		// 			video.setAttribute('controls', true);
		// 		}, false);
		// 		video.addEventListener('ended', function () {
		// 			video.removeAttribute('controls');
		// 			button.classList.remove('is-playing');
		// 		});
		// 	})(videoButton, videoEl);
		// }
	}

	// initializes slideshow using default wp video blocks in a group
	// altProductVideos() {

	// 	const el = document.querySelector('#product-videos');

	// 	if (!el) return;

	// 	const videos = el.querySelectorAll('.wp-block-video');

	// 	// store length before we move els
	// 	const videosLength = videos.length;

	// 	// if more than 4 videos, build dom for swiper slideshow
	// 	if (videosLength <= 4) return;

	// 	console.log(`creating swiper for ${videosLength} wp-block-videos`);

	// 	const blockEl = videos[0].parentNode;
	// 	const wrapperEl = document.createElement('DIV');
	// 	const sliderEl = document.createElement('DIV');

	// 	wrapperEl.classList.add('gab-videos-wrapper');
	// 	sliderEl.classList.add('swiper');

	// 	let i, j, slideEl, remaining, group;

	// 	for (i = 0; i < Math.ceil(videosLength / 4); i++) {

	// 		// create slide div for every 4 videos
	// 		slideEl = document.createElement('DIV');
	// 		slideEl.classList.add('swiper-slide');

	// 		// add it to our new sliderEl
	// 		sliderEl.appendChild(slideEl);

	// 		remaining = videosLength - i * 4;
	// 		group = (remaining >= 4) ? 4 : remaining;

	// 		//console.log(`${remaining} / ${group}`);

	// 		// fill the slide div with videos
	// 		for (j = 0; j < group; j++) {
	// 			slideEl.appendChild(videos[i * 4 + j]);
	// 		}
	// 	}

	// 	// add our slider to the dom
	// 	wrapperEl.appendChild(sliderEl);
	// 	blockEl.appendChild(wrapperEl);

	// 	this.swiper = new Swiper(sliderEl, {
	// 		modules: [Navigation, Pagination],
	// 		spaceBetween: 10,
	// 		slidesPerView: 1,
	// 		//slideClass: 'wp-block-video',
	// 		createElements: true,
	// 		navigation: true,
	// 		pagination: true,
	// 	});
	// }
}
