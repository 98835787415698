import Swiper from 'swiper';
import { Navigation, Pagination, Grid } from 'swiper/modules';

/**
 * source for slideshow block swiper settings
 * 
 * NOTE:
 *     if adding a block here, please set all properties
 *     or add check for prop in setup method
 * 
 * TODO:
 *     will definitely need to test be able to pass swiper args straight through (breakpoints, etc.)
 *     may want / need a default args also
 */
const BLOCK_SLIDESHOW_ARGS = [
	{
		blockClass: '.featured-literature',
		settings: {
			swiperArgs: {
				loop: false,
				slidesPerView: 1,
				breakpoints: {
					782: {
						slidesPerView: 3,
					},
				},
			},
			pagination: { enabled: true },
			navigation: { enabled: true }
		},
		modules: [
			Pagination,
			Navigation,
		],
	},
	{
		blockClass: '.featured-product-categories__mobile-slideshow',
		settings: {
			swiperArgs: {
				loop: true,
				slidesPerView: 1.4,
				centeredSlides: true,
			},
			pagination: { enabled: true },
			navigation: { enabled: false },
		},
		modules: [
			Pagination,
		],
	},
	{
		blockClass: '.featured-product-categories__desktop-slideshow',
		settings: {
			swiperArgs: {
				loop: false,
				slidesPerView: 3,
				breakpoints: {
					1786: { slidesPerView: 4, },
					2206: { slidesPerView: 5, },
					2626: { slidesPerView: 6, },
					3046: { slidesPerView: 7, },
					3466: { slidesPerView: 8, },
					3886: { slidesPerView: 9, }
				}
			},
			pagination: { enabled: true },
			navigation: { enabled: true },
		},
		modules: [
			Pagination,
			Navigation,
		],
	},
	{
		blockClass: '.product-videos',
		settings: {
			swiperArgs: {
				loop: false,
				slidesPerView: 1,
				grid: {
					rows: 2,
					fill: 'row',
				},
				spaceBetween: 0,
				breakpoints: {
					782: {
						slidesPerView: 2,
						navigation: { enabled: true },
						grid: {
							rows: 2,
						}
					},
				}
			},
			pagination: { enabled: true },
			navigation: { enabled: false },
			grid: true,
		},
		modules: [
			Pagination,
			Navigation,
			Grid,
		],
	},
	{
		blockClass: '.tm-sheets',
		settings: {
			swiperArgs: {
				loop: false,
				slidesPerView: 2,
				spaceBetween: 23,
				breakpoints: {
					782: {
						slidesPerView: 5,
						spaceBetween: 0,
					}
				}
			},
			pagination: { enabled: true },
			navigation: { enabled: true }
		},
		modules: [
			Pagination,
			Navigation,
		],
	},
	// {
	// 	blockClass: '.special-features',
	// 	settings: {
	// 		swiperArgs: {
	// 			loop: false,
	// 			slidesPerView: 1,
	// 		},
	// 		pagination: true,
	// 		navigation: true,
	// 	},
	// }
];

/**
 * BlockSlideshows
 * 
 * manage any number of swiper instances for this block type
 * 
 * TODO:
 *     store slideshow instances in case they need to altered later in runtime
 */
export class BlockSlideshows {
	constructor() { }

	setup() {

		let i, j, blockEls, slideshow;

		// loop BLOCK_SLIDESHOW_ARGS and instantiate BlockSlideshows for block els found
		for (i = 0; i < BLOCK_SLIDESHOW_ARGS.length; i++) {

			blockEls = document.querySelectorAll(BLOCK_SLIDESHOW_ARGS[i].blockClass);

			for (j = 0; j < blockEls.length; j++) {
				slideshow = new BlockSlideshow(
					blockEls[j],
					BLOCK_SLIDESHOW_ARGS[i].settings,
					BLOCK_SLIDESHOW_ARGS[i].modules
				);
				slideshow.setup();
			}
		}
	}
}

/**
 * init, manage Swiper slideshow for single instance of this block type
 * 
 * @param el NodeElement the block's containing dom element
 * @param settings Object slideshow settings, from BLOCK_SLIDESHOW_ARGS above
 */
class BlockSlideshow {
	constructor(el, settings, modules) {

		this.el = el;

		this.settings = settings;

		this.modules = modules;

		this.init = false;

		this.swiper = null;
	}

	setup() {

		if (this.init) return;

		this.init = true;

		//const modules = [];

		// const slidesPerView = (this.settings.swiperArgs.hasOwnProperty('slidesPerView')) ? this.settings.swiperArgs.slidesPerView : 1;

		// check enough slides to require swiper
		// if (this.el && this.el.querySelectorAll('.swiper-slide').length <= slidesPerView) {
		// 	this.el.classList.add('swiper-not-initialized');
		// 	return;
		// }

		// check add pagination to swiper args
		if (this.modules.indexOf(Pagination) >= -1) {

			//modules.push(Pagination);

			this.settings.swiperArgs.pagination = {
				...this.settings.swiperArgs.pagination,
				el: this.el.querySelector('.swiper-pagination'),
				clickable: true,
			};
		}

		// check add navigation to swiper args
		if (this.modules.indexOf(Navigation) > -1) {

			//modules.push(Navigation);

			this.settings.swiperArgs.navigation = {
				...this.settings.swiperArgs.navigation,
				nextEl: this.el.querySelector('.swiper-button-next'),
				prevEl: this.el.querySelector('.swiper-button-prev'),
			};
		}

		// if (this.settings.grid) {
		// 	modules.push(Grid);
		// }

		const swiperArgsFull = {
			...this.settings.swiperArgs,
			modules: this.modules,
		};

		console.log('swiperArgsFull', swiperArgsFull);

		this.swiper = new Swiper(this.el.querySelector('.swiper'), swiperArgsFull);
	}
}