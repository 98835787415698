const category_mismatches = [
	['washerextractors', 'washers-extractors'],
	['material-handlingshuttles-and-safety-devices', 'material-handling-shuttles-safety-devices']
];

const product_mismatches = [
	['smoothflow-batch-tunnel-washers-130-lb-model', 'smoothflow-batch-tunnel-washer-130-lb-model'],
	['smoothflow-batch-tunnel-washers-150-lb-and-220-lb-models', 'smoothflow-batch-tunnel-washer-150-lb-and-220-lb-models'],
	['open-pocket-washerextractors', 'open-pocket-washer-extractors'],
	['top-side-loader-washerextractors-medicare', 'top-side-loader-washer-extractors-medicare'],
	['top-side-loader-washerextractors-standard', 'top-side-loader-washer-extractors-standard'],
	['pt-pass-thru-dryers', 'pt-pass-thru-dryers-chute-load'],
	['sbs-side-side-dryer-non-chute-load', 'sbs-side-by-side-dryer-non-chute-load'],
	['sbs-side-side-chute-load-dryers', 'sbs-side-by-side-chute-load-dryers'],
	['safeload-shuttle-system-ride-chute-load', 'safeload-shuttle-system-ride-on-chute-load'],
	['safeload-ride-shuttle-system', 'safeload-ride-on-shuttle-system'],
	['precision-series-small-piece-folders-return-feed', 'precision-series-small-piece-folder-return-to-feed'],
	['precision-series-small-piece-folders-rear-discharge-model', 'precision-series-small-piece-folder-rear-discharge'],
	['precision-series-small-piece-folders-triple-sort', 'precision-series-small-piece-folder-triple-sort'],
	['precision-series-topline-folder', 'precision-series-topline-air-folder'],
	['precision-series-foldercross-folder', 'precision-series-folder-cross-folder'],
	['precision-series-blanket-and-knitted-fitted-sheet-folder', 'precision-series-blanket-knitted-fitted-sheet-folder'],
	['napkin-accumulator', 'precision-series-napkinaccumulator'],
	['stackers', 'precision-series-stackers'],
	['small-piece-rotary-accumulator', 'precision-series-small-piece-rotary-accumulator'],
];

/**
 * checkAdRedirect
 * 
 * handle existing ad links to products, categories of form
 *   #product-categories/batch-tunnel-washers
 *   #product/tunnel-load-conveyor
 * @returns null
 */
export function checkAdRedirect() {
	let hash = window.location.hash;
	let args, redirect, i, flag;

	if (!hash) return;

	hash = hash.slice(1);

	args = hash.split('/');

	if (args.length < 2) return;

	if (args[0] == 'product-categories') {

		redirect = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/product-category/';

		for (i = 0, flag = false; i < category_mismatches.length; i++) {
			if (args[1] == category_mismatches[i][0]) {
				redirect += category_mismatches[i][1];
				flag = true;
				break;
			}
		}

		if (!flag) redirect += args[1];

		//console.log(`redirect to category: ${redirect}`);

		window.location.replace(redirect);

	} else if (args[0] == 'product') {

		redirect = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/product/';

		for (i = 0, flag = false; i < product_mismatches.length; i++) {
			if (args[1] == product_mismatches[i][0]) {
				redirect += product_mismatches[i][1];
				flag = true;
				break;
			}
		}

		if (!flag) redirect += args[1];

		//console.log(`redirect to product: ${redirect}`);

		window.location.replace(redirect);
	}
}